
//@ts-disable
import { defineComponent } from "vue";
import { store } from "@/store";
import dateFormat from "dateformat";
import draggable from 'vuedraggable'
import AddFolderModal from "./modals/folder/AddFolderModal.vue";
import UpdateFolderModal from "./modals/folder/UpdateFolderModal.vue";
import DeleteFolderModal from "./modals/folder/DeleteFolderModal.vue";
import AddWebsiteModal from "./modals/website/AddWebsiteModal.vue";
import DeleteWebsiteModal from "./modals/website/DeleteWebsiteModal.vue";
import UpdateWebsiteModal from "./modals/website/UpdateWebsiteModal.vue";
import ViewWebsiteModal from "./modals/website/ViewWebsiteModal.vue";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import WebsiteAddedModal from "./modals/website/WebsiteAddedModal.vue";
import WebsiteList from "./components/WebsiteList.vue";
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
    components: {
        AddWebsiteModal,
        DeleteWebsiteModal,
        UpdateWebsiteModal,
        ViewWebsiteModal,
        LoadingSpinner,
        WebsiteAddedModal,
        WebsiteList,
        ChevronDownIcon,
        ChevronUpIcon,
        PencilSquareIcon,
        TrashIcon,
        draggable,
        AddFolderModal,
        UpdateFolderModal,
        DeleteFolderModal
    },
    emits: ["close"],
    data: () => {
        return {
            selectedWebsite: {} as any,
            selectedFolder: {} as any,
            addedWebsite: {} as any,
            selectedDefaultDate: "",
            mouseOver: false,
        };
    },
    props: {
    },
    watch: {
        folders: {
            handler: function (value) {
                store.dispatch("workspaceFolders/updateFolders", value)
            },
            deep: true
        }
    },
    computed: {
        getPossibleWebsites: function () {
            return store.getters["workspace/getPossibleWebsites"];
        },
        websites: function () {
            return store.getters["website/getWebsites"];
        },
        websitesLoaded: function () {
            return store.getters["website/websitesLoaded"];
        },
        folders: {
            get: function () {
                return store.getters["workspaceFolders/getFolders"]
            },
            set: function (value: any) {
                if (value) {
                    store.dispatch("websiteFolders/updateFolders", value)
                }
            }
        }
    },
    methods: {
        openModal: function (name: string) {
            const el = this.$refs[name] as any;
            el.click();
        },
        saveFolderOrder: async function () {
            console.log('saveFolderOrder');
            return;
        },
    },
});
