
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {
  },
  emits: ["reload"],
  data() {
    return {
      isChecked: false,
      submitted: false,
      error: '',
      data: {
        name: "",
      } as any,
    };
  },
  watch: {},
  methods: {
    reset: function () {
      this.data = {
        name: "",
      };

      this.submitted = false;
      this.error = "";
    },
    submit: async function () {
      this.submitted = true;
      this.error = "";

      await store.dispatch("workspaceFolders/addFolder", this.data)
        .then(() => {
          const el = this.$refs["closeBtn"] as any;
          this.submitted = false;
          el.click();
          this.reset();
        })
        .catch((err: any) => {
          store.dispatch("website/loadAll");
          if (err.response && err.response.data && err.response.data.e && err.response.data.e.message) {
            this.error = err.response.data.e.message;
          }
        });
    },
  },
});
