import { store } from "@/store";
import { getProduct } from "@/views/pages/settings/Payment/Paddle/paddle";
import type { Website } from "@scrap-x/website/types/website";

const DEFAULT_FOLDER_NAME = "Default";

export interface EnrichedFolder {
    id: string;
    name: string;
    collapsed: boolean;
    websites: Website[];
}

export interface StoredFolder {
    id: string;
    name: string;
    collapsed: boolean;
    websites: string[];
}

export default {
    namespaced: true,
    state: {
        folders: [] as EnrichedFolder[],
    },
    getters: {
        getFolders: (state: any) => {
            return state.folders;
        }
    },
    actions: {
        async addFolder(context: any, data: { name: string }): Promise<any> {
            await context.commit('ADD_FOLDER', data);
        },
        async updateFolder(context: any, data: { folderId: string, name: string }): Promise<any> {
            await context.commit('UPDATE_FOLDER', data);
        },
        async deleteFolder(context: any, data: { folderId: string }): Promise<any> {
            await context.commit('DELETE_FOLDER', data);
        },

        async updateFolders(context: any, folders: any) {
            if(!folders.length) return
            
            context.commit('UPDATE_FOLDERS', folders);
            context.dispatch('storeFolders');
        },
        async storeFolders(context: any,) {
            const folderToStore = context.state.folders.map((folder: EnrichedFolder) => {
                const websites = folder.websites.map((website: Website) => website.websiteId);
                return {
                    ...folder,
                    websites
                }
            });

            store.dispatch('workspace/updateFolder', folderToStore);
        },
        async load(context: any) {
            const websites = store.getters["website/getWebsites"];
            const folders = store.getters["workspace/getGetFolders"] as StoredFolder[];
            const enrichedFolders = [] as EnrichedFolder[];

            if (folders.length === 0) {
                enrichedFolders.push({
                    id: Math.random().toString(36).substring(7),
                    name: DEFAULT_FOLDER_NAME,
                    collapsed: false,
                    websites: []
                });
            }

            folders.forEach((folder: StoredFolder) => {
                const enrichedFolder: EnrichedFolder = {
                    ...folder,
                    // Sorting in the websites
                    websites: folder.websites.map((website: string) => websites.find((w: Website) => w.websiteId === website)).filter((w: Website) => w)
                }
                enrichedFolders.push(enrichedFolder);
            })

            // Move remaining web pages to (new) default folder
            websites.forEach((website: Website) => {
                const folder = enrichedFolders.find((folder: EnrichedFolder) => folder.websites.find((w: Website) => w.websiteId === website.websiteId));
                if (!folder) {
                    const defaultFolder = enrichedFolders.find(f => f.name === DEFAULT_FOLDER_NAME);

                    if (defaultFolder) {
                        defaultFolder.websites.push(website);
                    } else {
                        enrichedFolders.push({
                            id: Math.random().toString(36).substring(7),
                            name: DEFAULT_FOLDER_NAME,
                            collapsed: false,
                            websites: [website]
                        });
                    }
                }
            })

            enrichedFolders[0].collapsed = false;

            await context.commit('UPDATE_FOLDERS', enrichedFolders);
        }
    },
    mutations: {
        UPDATE_FOLDERS(state: any, folders: EnrichedFolder[]) {
            state.folders = folders;
        },
        ADD_FOLDER(state: any, data: { name: string }) {
            const folder: StoredFolder = {
                id: Math.random().toString(36).substring(7),
                name: data.name,
                collapsed: false,
                websites: []
            }

            state.folders.unshift(folder);
        },
        UPDATE_FOLDER(state: any, data: { folderId: string, name: string }) {
            state.folders = state.folders.map((folder: EnrichedFolder) => {
                if (folder.id === data.folderId) {
                    return {
                        ...folder,
                        name: data.name
                    }
                }
                return folder;
            })
        },
        DELETE_FOLDER(state: any, data: { folderId: string }) {
            state.folders = state.folders.filter((folder: EnrichedFolder) => folder.id !== data.folderId);
        },
    },
}
