
import { defineComponent } from "vue";
import { store } from "@/store";
import { Diff } from 'vue-diff';
import dateFormat from "dateformat";
import { historyIndex } from "@scrap-x/website/src/websiteHistory";
import { historyShow as historyShowApi } from "@scrap-x/website/src/websiteHistory";
import { show as showApi } from "@scrap-x/website/src/website";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import 'vue-diff/dist/index.css';

export default defineComponent({
  components: {
    Diff,
    LoadingSpinner
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
    defaultDate: {
      type: String,
      default: "",
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      error: '',
      md: null as any,
      websiteWithReponse: {} as any,
      displayType: 'text-only-changes',
      historyItems: [] as any,
      selectedItem: {} as any,
      slimSelectedItem: {} as any,
      selectedPrevItem: {} as any,
      selectedNextItem: {} as any,
    };
  },
  watch: {
    defaultDate: function () {
      if (this.defaultDate && this.historyItems.length) {
        this.selectHistory(this.historyItems[0].websiteId, Number(this.defaultDate));
      }
    },
    website: {
      immediate: true,
      handler: function () {
        this.error = '';
        this.selectedItem = {};
        this.displayType = 'text-only-changes';
        this.loadWebsite();
        this.loadHistory();
      }

    },
    slimSelectedItem: function (v) {
      this.selectHistory(v.websiteId, v.timestamp);
    },
  },
  computed: {
    showPayWall: function () {
      return !store.getters["workspace/getActiveWorkspace"].isPaid &&
        store.getters["workspace/getActiveWorkspace"].isOlderThen2Months;
    },
    loaded: function () {
      return this.websiteWithReponse && this.selectedItem && this.selectedNextItem.response && Object.keys(this.selectedNextItem.response).length;
    },
    onylChanges: function () {
      return this.displayType === 'text-only-changes';
    },
    slimSelectedItemTimestamp: {
      get() {
        return this.selectedNextItem?.timestamp;
      },
      set(v: any) {
        if (v != this.selectedNextItem?.timestamp) {
          this.selectHistory(this.website.websiteId, v);
        }
      },
    },
    next: function () {
      let displayType = this.displayType;
      displayType = displayType === 'text-only-changes' ? 'text' : displayType;
      if (this.selectedNextItem && this.selectedNextItem.response) {
        return this.selectedNextItem.response[displayType];
      }
      return '';
    },
    prev: function () {
      let displayType = this.displayType;
      displayType = displayType === 'text-only-changes' ? 'text' : displayType;

      if (this.selectedPrevItem && this.selectedPrevItem.response) {
        return this.selectedPrevItem.response[displayType];
      }

      return "";
    },
    workspaceId: function () {
      return store.getters["workspace/getActiveWorkspace"].workspaceId;
    },
  },
  methods: {
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    emptyHistory: function () {
      this.selectedItem = {
        response: {
          raw: '',
          markdown: '',
          text: '',
          image: '',
        }
      };
    },
    selectHistory: async function (websiteId: string, timestamp: number) {
      await this.selectNext(websiteId, timestamp);

      const sortedItems = this.historyItems
        .filter((item: any) => item.updatedAt)
        .sort((a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());

      const previousItem = sortedItems.reverse().find((item: any) => item.updatedAt < this.slimSelectedItemTimestamp);

      if (previousItem) {
        this.selectPrev(websiteId, previousItem.timestamp);
      } else {
        this.selectedPrevItem = "";
      }
    },
    selectPrev: async function (websiteId: string, timestamp: number) {
      const res = await historyShowApi(websiteId, timestamp, store.getters["getJWT"]);
      this.selectedPrevItem = res;
    },
    selectNext: async function (websiteId: string, timestamp: number) {
      const res = await historyShowApi(websiteId, timestamp, store.getters["getJWT"]);
      this.selectedNextItem = res;
    },
    loadWebsite: async function () {
      if (this.website.workspaceId && this.website.websiteId) {
        this.websiteWithReponse = await showApi(this.website.workspaceId, this.website.websiteId, store.getters["getJWT"]);
      }
    },
    loadHistory: async function () {
      if (!this.website.workspaceId) {
        return
      }
      this.historyItems = await historyIndex(this.website.websiteId, store.getters["getJWT"]);
      this.historyItems.pop();
      this.historyItems = this.historyItems.filter((item: any) => item.updatedAt);
      this.historyItems = this.historyItems.sort((a: any, b: any) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });

      if (!this.historyItems.length) {
        this.emptyHistory();
        return;
      }

      if (this.defaultDate) {
        this.selectHistory(this.historyItems[0].websiteId, Number(this.defaultDate));
      } else {
        this.selectHistory(this.historyItems[0].websiteId, this.historyItems[0].timestamp);
      }
    },
  },
});
