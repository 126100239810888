
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      name: "",
      folderName: "",
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
  },
  watch: {
    folder: function (v: any) {
      if (v) {
        this.folderName = v.name;
      }
    },
  },
  mounted() {
    this.name = "";
    this.folderName = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      await store.dispatch("workspaceFolders/deleteFolder", { folderId: this.folder.id, name: this.name });
      this.submitted = false;
      const el = this.$refs["closeBtn"] as any;
      el.click();
    },
  },
});
