
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {
  },
  props: {
    folder: {
      type: Object,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      error: null as any,
      name: this.folder.name,
    };
  },
  computed: {
    isDisabled() {
      return !this.name;
    },
  },
  watch: {
    folder: function (v) {
      this.reset()
    }
  },
  mounted() {
    this.submitted = false;
    this.reset();
  },
  methods: {
    reset: function () {
      this.name = this.folder.name;
    },
    submit: async function () {
      this.submitted = true;

      await store.dispatch("workspaceFolders/updateFolder", { folderId: this.folder.id, name: this.name })
        .then(() => {
          const el = this.$refs["closeBtn"] as any;
          this.submitted = false;
          el.click();
          this.reset();
        })
        .catch((err: any) => {
          store.dispatch("website/loadAll");
          if (err.response && err.response.data && err.response.data.e && err.response.data.e.message) {
            this.error = err.response.data.e.message;
          }
        });
    },
  },
});
