import { store } from "@/store";
import { getAll as getAllWebsites } from "@scrap-x/website/src/website";
import { store as storeApi } from "@scrap-x/website/src/website";
import { update as updateApi } from "@scrap-x/website/src/website";
import { remove as removeApi } from "@scrap-x/website/src/website";

export default {
    namespaced: true,
    state: {
        websites: [] as any[],
        websitesLoaded: [] as any[],
    },
    getters: {
        getWebsites: (state: any) => {
            return state.websites;
        },
        websitesLoaded: (state: any) => {
            return state.websitesLoaded;
        },
    },
    actions: {
        async loadAll(context: any): Promise<any> {
            context.commit('SET_WEBSITES', []);
            context.commit('SET_WEBSITE_LOADED', false);
            const res = await getAllWebsites(
                store.getters["workspace/getActiveWorkspace"].workspaceId,
                store.getters["getJWT"]
            );

            await context.commit('SET_WEBSITES', res);
            await context.commit('SET_WEBSITE_LOADED', true);
            store.dispatch('workspaceFolders/load');
        },

        async add(context: any, data: { url: string, transformer: string, headers?: any[], active: boolean }): Promise<any> {
            const res = await storeApi(
                store.getters["workspace/getActiveWorkspace"].workspaceId,
                data,
                store.getters["getJWT"]
            ) as any;

            context.commit('ADD_WEBSITE', res);
            return res;
        },
        async update(context: any, data: { websiteId: string, payload: { url: string, transformer: string, headers?: any[], active: boolean } }): Promise<any> {
            const res = await updateApi(
                store.getters["workspace/getActiveWorkspace"].workspaceId,
                data.websiteId,
                data.payload,
                store.getters["getJWT"]
            ) as any;

            context.commit('UPDATE_WEBSITE', { websiteId: data.websiteId, res });
            return res;
        },
        async remove(context: any, websiteId: string): Promise<any> {
            const res = await removeApi(
                store.getters["workspace/getActiveWorkspace"].workspaceId,
                websiteId,
                store.getters["getJWT"]
            ) as any;

            context.commit('REMOVE_WEBSITE', websiteId);
            return res;
        }
    },
    mutations: {
        SET_WEBSITES(state: any, websites: any[]) {
            state.websites = websites;
        },
        SET_WEBSITE_LOADED(state: any, websitesLoaded: boolean) {
            state.websitesLoaded = websitesLoaded;
        },
        ADD_WEBSITE(state: any, website: any) {
            state.websites.push(website);
            store.dispatch('workspaceFolders/load');
        },
        UPDATE_WEBSITE(state: any, data: { websiteId: string, res: any }) {
            state.websites = state.websites.map((website: any) => {
                if (website.websiteId === data.websiteId) {
                    return data.res;
                }
                return website;
            });
            store.dispatch('workspaceFolders/load');
        },
        REMOVE_WEBSITE(state: any, websiteId: any) {
            state.websites = state.websites.filter((website: any) => website.websiteId !== websiteId);
            store.dispatch('workspaceFolders/load');
        },
    },
}
