
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  components: {},
  emits: ["reload"],
  props: {
    addedWebsite: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["workspace/getActiveWorkspaceId"];
    },
    healthCheckUrl: function () {
      return process.env.VUE_APP_SDK_ENVIRONMENT === 'production'
        ? `https://healthcheck.app.scrapx.io/${this.workspaceId}/${this.addedWebsite.websiteId}`
        : `https://healthcheck.stage.scrapx.io/${this.workspaceId}/${this.addedWebsite.websiteId}`;
    },
  },
  watch: {},
});
