
//@ts-disable
import { defineComponent } from "vue";
import { store } from "@/store";
import WebsiteDetail from "./components/WebsiteDetailV2.vue";
import WebsiteDetailImage from "./components/WebsiteDetailImage.vue";
import { show as showApi } from "@scrap-x/website/src/website";
import { Website } from "@scrap-x/website/types/website";

export default defineComponent({
    components: {
        WebsiteDetail,
        WebsiteDetailImage
    },
    data: () => {
        return {
            website: {} as Website,
        };
    },
    watch: {
    },
    computed: {
    },
    async mounted() {
        this.website = await showApi(String(this.$route.params.workspaceId), String(this.$route.params.websiteId), store.getters["getJWT"])
    }
});
