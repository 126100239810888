import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4d9b864"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "viewWebsiteModal",
  tabindex: "-1",
  "data-bs-backdrop": "true",
  "aria-labelledby": "viewWebsiteModal",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsiteDetail = _resolveComponent("WebsiteDetail")!
  const _component_WebsiteDetailImage = _resolveComponent("WebsiteDetailImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.website.type !== 'IMAGE' )
          ? (_openBlock(), _createBlock(_component_WebsiteDetail, {
              key: 0,
              defaultDate: _ctx.defaultDate,
              website: _ctx.website
            }, null, 8, ["defaultDate", "website"]))
          : _createCommentVNode("", true),
        (_ctx.website.type === 'IMAGE' )
          ? (_openBlock(), _createBlock(_component_WebsiteDetailImage, {
              key: 1,
              defaultDate: _ctx.defaultDate,
              website: _ctx.website
            }, null, 8, ["defaultDate", "website"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}