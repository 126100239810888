
import { defineComponent } from "vue";
import { store } from "@/store";
import { destroy as removeApi } from "@scrap-x/workspace/src/permission";

export default defineComponent({
  components: {},
  props: {
    website: {
      type: Object,
      required: true,
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      loading: false,
      url: "",
      websiteUrl: "",
    };
  },
  computed: {
    workspaceId: function () {
      return store.getters["getBaseWorkspaceId"];
    },
  },
  watch: {
    website: function (v: any) {
      if (v) {
        this.websiteUrl = v.url;
      }
    },
  },
  mounted() {
    this.url = "";
    this.websiteUrl = "";
  },
  methods: {
    submit: async function () {
      this.submitted = true;
      await store.dispatch("website/remove", this.website.websiteId);
      
      this.submitted = false;
      this.websiteUrl = "";
      this.url = "";
      const el = this.$refs["closeBtn"] as any;
      el.click();
      this.$emit("reload");
    },
  },
});
