import { store } from "@/store";
import { getProduct } from "@/views/pages/settings/Payment/Paddle/paddle";
import { getAllByUser as getAllWorkspacesByUser, store as storeApi, update as updateApi, remove as removeApi, show as showApi } from "@scrap-x/workspace/src/workspace";
import { getProgressInstance } from '@/progress';

const STORAGE_NAME = "defaultWorkspace";

export default {
    namespaced: true,
    state: {
        activeWorkspace: {},
        workspaces: [] as any[],
        noWorkspaces: false,
    },
    getters: {
        getWorkspaces: (state: any) => {
            return state.workspaces;
        },
        getGetFolders: (state: any) => {
            return state.activeWorkspace?.folders || null
        },
        getActiveWorkspace: (state: any) => {
            return state.activeWorkspace;
        },
        getActiveWorkspaceId: (state: any) => {
            return state.activeWorkspace.workspaceId;
        },
        hasNoWorkspaces: (state: any) => {
            return state.noWorkspaces;
        },
        isPaidAccount: (state: any) => {
            return state.activeWorkspace.isPaid;
        },
        isOlderThen2Months: (state: any) => {
            return state.activeWorkspace.isOlderThen2Months;
        },
        canUseBasicFeatures: (state: any): boolean => {
            const items = state.activeWorkspace?.paddleSubscription?.items;

            if (!items) {
                return false;
            }

            return !!items
                .filter((i: any) => i.status === "active" || i.status === "trialing")
                .map((i: any) => i.price.product_id)
                .map((id: any) => getProduct(id))
                .filter((p: any) => ['Basic', 'Pro', 'Mega'].includes(p.name))
                .length;
        },
        canUseProFeatures: (state: any): boolean => {
            const items = state.activeWorkspace?.paddleSubscription?.items;

            if (!items) {
                return false
            }

            return !!items
                .filter((i: any) => i.status === "active" || i.status === "trialing")
                .map((i: any) => i.price.product_id)
                .map((id: any) => getProduct(id))
                .filter((p: any) => ['Pro', 'Mega'].includes(p.name))
                .length;
        },
        getActivePriceId: (state: any): string => {
            const items = state.activeWorkspace?.paddleSubscription?.items;

            if (!items) {
                return "";
            }

            const actievOnes = items
                .filter((i: any) => i.status === "active" || i.status === "trialing");

            if (actievOnes.length > 0) {
                return actievOnes[0].price.id;
            }

            return "";
        },
        getPossibleWebsites: (state: any) => {
            const items = state.activeWorkspace?.paddleSubscription?.items;

            if (!items) {
                return 1;
            }

            return items
                .filter((i: any) => i.status === "active" || i.status === "trialing")
                .map((i: any) => { return getProduct(i.price.product_id).websites; })
                .reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);
        },
        isCanceledAt: (state: any) => {
            if (state.activeWorkspace?.paddleSubscription?.scheduled_change?.action == 'cancel') {
                return state.activeWorkspace?.paddleSubscription?.scheduled_change?.effective_at;
            }

            return '';
        },
        trailUntil: (state: any) => {
            const items = state.activeWorkspace?.paddleSubscription?.items;

            if (!items) {
                return null;
            }

            return items
                .filter((i: any) => i.status === "trialing")
                .map((data: any) => {
                    return data.trial_dates.ends_at;
                }).reduce((latest: any, current: any) => {
                    return latest > current ? latest : current;
                }, null);
        },
        getPaddlePlanNames: (state: any) => {
            const items = state.activeWorkspace?.paddleSubscription?.items;

            if (!items) {
                return [];
            }

            return items
                .filter((i: any) => i.status === "active" || i.status === "trialing")
                .map((i: any) => i.price.product_id)
                .map((id: any) => getProduct(id));
        },
    },
    actions: {
        async loadAll(context: any, params: any): Promise<any> {
            context.commit('SET_NO_WORKSPACE', false);

            const res = await getAllWorkspacesByUser(
                store.getters["getUserEmail"],
                store.getters["getJWT"]
            );

            context.commit('SET_WORKSPACES', { workspaces: res, initialWorkspaceId: params?.workspaceId });
            context.commit('SET_NO_WORKSPACE', !res.length);

            if (res.length) {
                store.dispatch("website/loadAll");
            }

            return res;
        },

        async updateFolder(context: any, folders: []): Promise<any> {
            const progress = getProgressInstance();
  
            if (JSON.stringify(folders.map((i: any) => ({ ...i, collapsed: true }))) === JSON.stringify(context.state.activeWorkspace.folders.map((i: any) => ({ ...i, collapsed: true })))) {
                return;
            }

            await updateApi(
                context.state.activeWorkspace.workspaceId,
                { folders },
                store.getters["getJWT"]
            ) as any;

            progress.start();
            await context.commit('UPDATE_FOLDERS', folders);
            progress.finish();
            //showToast.success("Folder Structure Stored");
        },

        async add(context: any, name: string): Promise<any> {
            const res = await storeApi(
                { name },
                store.getters["getJWT"]
            ) as any;

            await context.commit('ADD_WORKSPACE', res);
            store.dispatch("website/loadAll");
            return res;
        },
        async updateActiveAction(context: any, action: any): Promise<any> {
            const res = await updateApi(
                context.state.activeWorkspace.workspaceId,
                { action },
                store.getters["getJWT"]
            ) as any;

            context.commit('UPDATE_WORKSPACE', res);
            return res;
        },
        async refreshActive(context: any): Promise<any> {
            const res = await showApi(
                context.state.activeWorkspace.workspaceId,
                store.getters["getJWT"]
            ) as any;

            context.commit('UPDATE_WORKSPACE', res);
            return res;
        },
        async remove(context: any, workspaceId: string): Promise<any> {
            const res = await removeApi(
                workspaceId,
                store.getters["getJWT"]
            ) as any;

            context.commit('REMOVE', workspaceId);
            context.dispatch("loadAll");
        },
        async setActive(context: any, workspaceId: string) {
            const res = context.state.workspaces.find((workspace: any) => workspace.workspaceId === workspaceId);
            if (!res) {
                return;
            }
            context.commit('SET_ACTIVE_WORKSPACE', res);
            store.dispatch("website/loadAll");
            return res;
        },
    },
    mutations: {
        UPDATE_FOLDERS(state: any, folders: boolean) {
            state.activeWorkspace.folders = folders;
        },
        SET_WORKSPACES(state: any, data: { workspaces: any, initialWorkspaceId: string }) {
            const defaultWorkspaceId = localStorage.getItem(STORAGE_NAME);

            state.workspaces = data.workspaces;
            if (!state.activeWorkspace || !state.activeWorkspace.workspacID) {
                if (data.initialWorkspaceId) {
                    store.dispatch("workspace/setActive", data.initialWorkspaceId);
                } else if (defaultWorkspaceId && data.workspaces.find((w: any) => w.workspaceId === defaultWorkspaceId)) {
                    store.dispatch("workspace/setActive", defaultWorkspaceId);
                } else {
                    store.dispatch("workspace/setActive", data.workspaces[0]?.workspaceId);
                }
            }

            // TODO LOAD FOLDERS
        },
        SET_NO_WORKSPACE(state: any, has: boolean) {
            state.noWorkspaces = has;
        },
        SET_ACTIVE_WORKSPACE(state: any, workspace: any) {
            localStorage.setItem(STORAGE_NAME, workspace.workspaceId);
            state.activeWorkspace = workspace;
        },
        ADD_WORKSPACE(state: any, workspace: any) {
            localStorage.setItem(STORAGE_NAME, workspace.workspaceId);
            state.activeWorkspace = workspace;

            state.workspaces.push(workspace);
        },
        UPDATE_WORKSPACE(state: any, workspace: any) {
            state.workspaces = state.workspaces.map((w: any) => {
                if (w.workspaceId === workspace.workspaceId) {
                    return workspace;
                }
                return w;
            });

            if (workspace.workspaceId === state.activeWorkspace.workspaceId) {
                state.activeWorkspace = workspace;
            }
        },
        REMOVE(state: any, workspaceId: string) {
            state.workspaces = state.workspaces.filter((w: any) => w.workspaceId !== workspaceId);
        },
    },
}
