
import { defineComponent } from "vue";
import { store } from "@/store";
import dateFormat from "dateformat";
import { historyIndex } from "@scrap-x/website/src/websiteHistory";
import { historyShow as historyShowApi } from "@scrap-x/website/src/websiteHistory";
import { show as showApi } from "@scrap-x/website/src/website";
import LoadingSpinner from "@/views/components/LoadingSpinner.vue";
import 'vue-diff/dist/index.css';
import pixelmatch from 'pixelmatch';

export default defineComponent({
  components: {
    LoadingSpinner
  },
  props: {
    website: {
      type: Object,
      required: true,
    },
    defaultDate: {
      type: String,
      default: "",
    },
  },
  emits: ["reload"],
  data() {
    return {
      submitted: false,
      error: '',
      loading: false,
      md: null as any,
      websiteWithReponse: {} as any,
      historyItems: [] as any,
      selectedPrevItem: {} as any,
      selectedNextItem: {} as any,
      slimSelectedItem: {} as any,
      mixer: 50,
      defaultOptions: {
        threshold: 0.1,         // matching threshold (0 to 1); smaller is more sensitive
        includeAA: false,       // whether to skip anti-aliasing detection
        alpha: 0.1,             // opacity of original image in diff output
        aaColor: [255, 255, 0], // color of anti-aliased pixels in diff output
        diffColor: [255, 0, 0], // color of different pixels in diff output
        diffColorAlt: null as any,     // whether to detect dark on light differences between img1 and img2 and set an alternative color to differentiate between the two
        diffMask: false         // draw the diff over a transparent background (a mask)
      }
    };
  },
  watch: {
    defaultDate: function(){
      if (this.defaultDate && this.historyItems.length) {
        this.selectHistory(this.historyItems[0].websiteId, Number(this.defaultDate));
      }
    },
    website: {
      immediate: true,
      handler: function () {
        this.error = '';
        this.selectedNextItem = {};
        this.selectedPrevItem = {};
        this.mixer = 50;
        this.resetImages();
        this.loadWebsite();
        this.loadHistory();
      }

    },
    slimSelectedItem: function (v) {
      this.selectHistory(v.websiteId, v.timestamp);
    },
    prev: {
      immediate: true,
      handler: function () {
        if (this.next) {
          this.fetchAndProcessImages(this.prev, this.next);
        }
      }
    },
    next: {
      immediate: true,
      handler: function () {
        if (this.next) {
          this.fetchAndProcessImages(this.prev, this.next);
        }
      }
    },
    mixer: function (value: any) {
      let canvas1 = document.getElementById('canvas1');
      let canvas2 = document.getElementById('canvas2');
      let canvas3 = document.getElementById('canvas3');

      canvas2.style.opacity = String(value / 100);
      canvas1.style.opacity = String(1 - value / 100);

      if (value >= 25 && value <= 75) {
        canvas3.style.opacity = String(1);
      } else if (value < 25) {
        canvas3.style.opacity = String(value / 25);
      } else {
        canvas3.style.opacity = String(- (value - 75) / 25);
      }
    }
  },
  computed: {
    showPayWall: function () {
      return !store.getters["workspace/getActiveWorkspace"].isPaid &&
        store.getters["workspace/getActiveWorkspace"].isOlderThen2Months;
    },
    slimSelectedItemTimestamp: {
      get() {
        return this.selectedNextItem?.timestamp;
      },
      set(v: any) {
        if (v != this.selectedNextItem?.timestamp) {
          this.selectHistory(this.website.websiteId, v);
        }
      },
    },
    loaded: function () {
      return this.websiteWithReponse &&  Object.keys(this.selectedNextItem.response).length;
    },
    prev: function () {
      if (this.selectedPrevItem && this.selectedPrevItem.response) {
        return this.selectedPrevItem.response.image;
      }
      return '';
    },
    next: function () {
      if (this.selectedNextItem && this.selectedNextItem.response) {
        return this.selectedNextItem.response.image;
      }
      return '';
    },
    workspaceId: function () {
      return store.getters["workspace/getActiveWorkspace"].workspaceId;
    },
  },
  methods: {
    format: function (time: string) {
      return dateFormat(time, "dd.mm.yyyy HH:MM");
    },
    emptyHistory: function () {
      this.selectedNextItem = {
        response: {
          raw: '',
          markdown: '',
          text: '',
          image: '',
        }
      };
    },
    selectPrev: async function (websiteId: string, timestamp: number) {
      const res = await historyShowApi(websiteId, timestamp, store.getters["getJWT"]);
      this.selectedPrevItem = res;
    },
    selectNext: async function (websiteId: string, timestamp: number) {
      const res = await historyShowApi(websiteId, timestamp, store.getters["getJWT"]);
      this.selectedNextItem = res;
    },
    selectHistory: async function (websiteId: string, timestamp: number) {
      await this.selectNext(websiteId, timestamp);

      const sortedItems = this.historyItems
        .filter((item: any) => item.updatedAt)
        .sort((a: any, b: any) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());

      const previousItem = sortedItems.reverse().find((item: any) => item.updatedAt < this.slimSelectedItemTimestamp);

      if (previousItem) {
        this.selectPrev(websiteId, previousItem.timestamp);
      } else {
        this.selectedPrevItem = "";
      }
    },
    loadWebsite: async function () {
      if (this.website.workspaceId && this.website.websiteId) {
        this.websiteWithReponse = await showApi(this.website.workspaceId, this.website.websiteId, store.getters["getJWT"]);
      }
    },
    loadHistory: async function () {
      if (!this.website.workspaceId) {
        return
      }
      this.historyItems = await historyIndex(this.website.websiteId, store.getters["getJWT"]);
      this.historyItems.pop();
      this.historyItems = this.historyItems.filter((item: any) => item.updatedAt);
      this.historyItems = this.historyItems.sort((a: any, b: any) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });
      if (!this.historyItems.length) {
        this.emptyHistory();
        return;
      }

      if (this.defaultDate) {
        this.selectHistory(this.historyItems[0].websiteId, Number(this.defaultDate));
      } else {
        this.selectHistory(this.historyItems[0].websiteId, this.historyItems[0].timestamp);
      }
    },

    ///
    fetchImage: async function (src: any) {
      return new Promise((resolve, reject) => {
        const image = new Image;
        image.crossOrigin = "anonymous";
        image.src = src;
        image.onload = () => resolve(image);
        image.onerror = reject;
      });
    },

    fetchAndProcessImages: async function (prev: string, next: string) {
      const baseUrl = process.env.VUE_APP_SDK_ENVIRONMENT === 'production' ?
        'https://d3fahkym5pp3g0.cloudfront.net/' :
        'https://d3jy9gcxpjks1s.cloudfront.net/';

      const img2 = await this.fetchImage(baseUrl + next) as HTMLImageElement;

      let img1 = null;

      if (prev) {
        img1 = await this.fetchImage(baseUrl + prev) as HTMLImageElement;
      }

      const { width: w, height: h } = img2;

      let canvas1 = document.getElementById('canvas1') as HTMLCanvasElement;
      let ctx1 = canvas1.getContext('2d');

      let canvas2 = document.getElementById('canvas2') as HTMLCanvasElement;
      let ctx2 = canvas2.getContext('2d');

      let canvas3 = document.getElementById('canvas3') as HTMLCanvasElement;
      let ctx3 = canvas3.getContext('2d');

      if (img1) {
        ctx1.drawImage(img1, 0, 0);
      } else {
        const dummyCanvas = document.createElement('canvas');
        dummyCanvas.width = img2.width;
        dummyCanvas.height = img2.height;
        ctx1.drawImage(dummyCanvas, 0, 0);
      }
      const data1 = ctx1.getImageData(0, 0, w, h);

      ctx2.drawImage(img2, 0, 0);
      const data2 = ctx2.getImageData(0, 0, w, h);

      const diff = ctx3.createImageData(w, h);
      const pixels = pixelmatch(data1.data, data2.data, diff.data, w, h, { threshold: 0.7, includeAA: true, diffMask: true });
      ctx3.putImageData(diff, 0, 0);
    },
    resetImages: function () {
      let canvas1 = document.getElementById('canvas1') as HTMLCanvasElement;
      if (canvas1) {
        let ctx1 = canvas1.getContext('2d');
        ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
      }

      let canvas2 = document.getElementById('canvas2') as HTMLCanvasElement;
      if (canvas2) {
        let ctx2 = canvas2.getContext('2d');
        ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
      }

      let canvas3 = document.getElementById('canvas3') as HTMLCanvasElement;
      if (canvas3) {
        let ctx3 = canvas3.getContext('2d');
        ctx3.clearRect(0, 0, canvas3.width, canvas3.height);
      }
    },
  },
});
