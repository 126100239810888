import { App } from 'vue';

let progress: any;

export function setProgressInstance(app: App) {
  progress = app.config.globalProperties.$progress;
}

export function getProgressInstance() {
  if (!progress) {
    throw new Error('Progress instance is not set');
  }
  return progress;
}